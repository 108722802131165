/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faFlagCheckered, faPause, faPenToSquare, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { MenuRight, CustomTableMain, SearchByDate, AddButton, AlertNotificactions } from "../../../components";
import { timeZones } from "../../../const/timeZones";
import { myExtensionPetition } from "../../../services/myExtension.service";
import { useDynamicFunction } from "../../../contexts/DynamicFunctionContext";
import { createFormData, validUSPhoneNumber } from "./utils";
import RefreshIcon from "@mui/icons-material/Refresh";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalInfomation from "./ModalUtility/ModalInfomation";
import petitionGet from "../../../services/petitionGet";
import petitionPost from "../../../services/petitionPost";
import petitionPatch from "../../../services/petitionPatch";
import petitionDelete from "../../../services/petitionDelete";
import AddCampaign from "./AddCampaign/AddCampaign";
import sms from "../../../assets/icons/sms.svg";
import bigEye from "../../../assets/icons/bigEye.svg";
import wait from "../../../assets/icons/wait.svg";
import view from "../../../assets/icons/view.svg";
import closeCircle from "../../../assets/icons/closeCircle.svg";
import contacts from "../../../assets/icons/contacts.svg";
import getQueryString from "../../../utils/getQueryString";
import moment from 'moment';
import 'moment-timezone';
import "./SmartCampaigns.css";

const SmartCampaigns = () => {
  const { resetFunction, setIsArrowVisible } = useDynamicFunction();

  const navigate = useNavigate()

  const myExtension = useRef({})
  const numbers = useRef([]);
  const itemsPerPage = useRef("10")

  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [stepAddCampaign, setStepAddCampaign] = useState(1);
  const [loadingAddSmartCampaign, setLoadingAddSmartCampaign] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [activePauseCampaign, setActivePauseCampaign] = useState(false);
  const [activePauseCampaignFailed, setActivePauseCampaignFailed] = useState(false);
  const [activePlayCampaign, setActivePlayCampaign] = useState(false);
  const [activeStartCampaign, setActiveStartCampaign] = useState(false);
  const [duplicateCampaign, setDuplicateCampaign] = useState(false)
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [defaultTimeZone, setDefaultTimeZone] = useState({})
  const [filter, setFilter] = useState({
    start_date: "",
    end_date: "",
  });
  const [filterStatus, setFilterStatus] = useState({ status: "all" });
  const [formAddCampaign, setFormAddCampaign] = useState({
    name: "",
    campaign_type: "",
    numbers: [],
    campaign_strategy: "",
    default_time_zone: {},
    message_text: "",
    mms_file: null,
    mms_mime: null,
    media_filename: null,
    contact_list: [],
    contacts: [],
    schedule_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    schedule_start_time: "09:00",
    schedule_end_time: "17:00",
    scheduled_date: "",
    determine_time_zone: false,
  });
  const [imgMessages, setImgMessages] = useState([]);
  const [optionsMessageBody, setOptionsMessageBody] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false)
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("")
  const [followUpsTable, setFollowUpsTable] = useState({ content: [], })
  const [followUpsList, setFollowUpsList] = useState([])

  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Name", key: "name" },
      { name: "Type", key: "formatedType" },
      { name: "Start Date", key: "scheduled_date" },
      { name: "Messages Delivered", key: "formatedDelivered" },
      { name: "Messages Failed", key: "formatedRejected" },
      { name: "Messages Pending", key: "formatedPending" },
      { name: "Responded", key: "formatedResponded" },
      { name: "Status", key: "formatedStatus" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const selectIcon = {
    sms: <img src={sms} alt="sms" />,
  };

  const closeMenu = () => {
    setDuplicateCampaign(false);
    setModalInfo(false);
    setOptionsMessageBody([])
    setActiveStartCampaign(false);
    setActivePlayCampaign(false);
    setActivePauseCampaign(false);
    setInfo(null);
    setModalDelete(false);
    setOpenMenu(false);
    setLoadingAddSmartCampaign(false);
    reloadForm();
  };

  const activeAddCampaign = () => {
    if (loading || loadingRefresh) return;

    setFollowUpsTable({
      columns: [
        { name: "Interval", key: "interval" },
        { name: "Interval Type", key: "interval_type" },
        { name: "Message", key: "message" },
      ],
      actions: { 
        title: "Actions", 
        content: [
          {
            name: "Edit",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
            handleClick: (e) => console.log(e),
          },
          {
            name: "Delete",
            icon: <FontAwesomeIcon icon={faTrash} />,
            handleClick: (e) => console.log(e),
          },
        ] 
      },
      content: [],
    })

    setOpenMenu(true);
  };

  const formatText = (text) => {
    switch (text) {
      case "sms":
        return "SMS Campaign";
      case "in_progress":
        return "In Progress";
      case "pending":
        return "Pending";
      case "completed":
        return "Completed";
      case "paused":
        return "Paused";
      case "starting":
        return "Starting";
      case "delivered":
        return "Delivered";
      case "dnd_sms":
        return "DND SMS";
      default:
        return text;
    }
  };

  const reloadForm = () => {
    setFormAddCampaign({
      name: "",
      campaign_type: "",
      numbers: [],
      campaign_strategy: "",
      default_time_zone: defaultTimeZone,
      message_text: "",
      mms_file: null,
      mms_mime: null,
      media_filename: null,
      contact_list: [],
      contacts: [],
      schedule_days: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
      schedule_start_time: "09:00",
      schedule_end_time: "17:00",
      scheduled_date: "",
      determine_time_zone: false,
    });
    setImgMessages([])
    setStepAddCampaign(1);
  };

  const reloadData = () => {
    let parameter = ""

    if (filterStatus.status === "all") {
      parameter = getQueryString(filter);
    } else {
      parameter = getQueryString(filter) + getQueryString(filterStatus).replaceAll("?", "&");
    }

    closeMenu();
    reloadForm();
    petition(parameter);
  };

  const selectTitle = () => {
    if (activePauseCampaign && !activePlayCampaign && !activeStartCampaign && !duplicateCampaign) {
      return "Pause Campaign";
    }

    if (!activePauseCampaign && activePlayCampaign && !activeStartCampaign && !duplicateCampaign) {
      return "Play Campaign";
    }

    if (!activePauseCampaign && !activePlayCampaign && activeStartCampaign && !duplicateCampaign) {
      return "Start Campaign";
    }

    if (!activePauseCampaign && !activePlayCampaign && !activeStartCampaign && duplicateCampaign) {
      return "Duplicate Campaign";
    }
    
  };

  const selectText = () => {
    if (!activePauseCampaign && !activePlayCampaign && !activeStartCampaign && !duplicateCampaign && !activePauseCampaignFailed) {
      return "If this smart campaign is deleted, all messages that have not yet been sent will not be sent."
    }
    if (!activePauseCampaign && !activePlayCampaign && !activeStartCampaign && !duplicateCampaign && activePauseCampaignFailed) {
      return "You can't modify while the campaign is paused."
    }
    else {
      return ""
    }
  }

  const selectModalFunction = () => {
    if (activePauseCampaign && !activePlayCampaign && !activeStartCampaign && !duplicateCampaign) {
      return pauseCampaign();
    }

    if (!activePauseCampaign && activePlayCampaign && !activeStartCampaign && !duplicateCampaign) {
      return playCampaign();
    }

    if (!activePauseCampaign && !activePlayCampaign && activeStartCampaign && !duplicateCampaign) {
      return startCampaign();
    }

    if (!activePauseCampaign && !activePlayCampaign && !activeStartCampaign && !duplicateCampaign) {
      return deleteCampaign();
    }

    if (!activePauseCampaign && !activePlayCampaign && !activeStartCampaign && duplicateCampaign) {
      return duplicateCampaignFunction();
    }
  };

  const handleChangeStatus = (e) => {
    if (loading || loadingRefresh) return;

    const { value } = e.target
    setFilterStatus({ status: value });

    let parameter = ""

    if (value === "all") {
      parameter = getQueryString(filter)
    } else {
      parameter = getQueryString(filter) + `&status=${value}`
    }

    petition(parameter)
  };

  const handleChangeItemsPerPage = (data) => {
    itemsPerPage.current = data
    forceUpdate()
  }

  const activeDuplicateCampaign = (e) => {
    setDuplicateCampaign(true)
    setInfo(e)
    setModalDelete(true);
  }

  const activeViewLogs = (e) => {
    navigate(`/Smart-Campaigns/View-Logs?pk=${e.pk}&name=${e.name}`)
  }

  const activeViewFollowTasks = (e) => {
    navigate(`/Smart-Campaigns/Follow-Tasks?pk=${e.pk}&name=${e.name}`)
  }

  const activeEditCampaign = (e) => {
      setInfo(e);
      setOpenMenu(true);
      setLoadingEdit(true);

      petitionGet("smartCampaignDetails", { campaign_id: e.pk })
        .then(async ({ data: result }) => {
          if(result.result.status === "paused"){
            setActivePauseCampaignFailed(true);
            setModalInfo(true);
          }
          else{
            await petitionGet("followUpsSmartCampaigns", { parameter: `?smart_campaign_id=${e.pk}` })
              .then(({ data: result }) => {
                const response = result.result.map((element) => {
                  element.interval = parseInt(element.interval)
                  return element
                })

                setFollowUpsList(response)
                setFollowUpsTable({
                  columns: [
                    { name: "Interval", key: "interval" },
                    { name: "Interval Type", key: "interval_type" },
                    { name: "Message", key: "message" },
                  ],
                  actions: { 
                    title: "Actions", 
                    content: [
                      {
                        name: "Edit",
                        icon: <FontAwesomeIcon icon={faPenToSquare} />,
                        handleClick: (e) => console.log(e),
                      },
                      {
                        name: "Delete",
                        icon: <FontAwesomeIcon icon={faTrash} />,
                        handleClick: (e) => console.log(e),
                      },
                    ] 
                  },
                  content: [...response],
                })
              })
              .catch((error) => console.log(error))

            let defaultNumbers = [];
            let defaultContactList = [];
            let defaultContacts = [];

            const defaultScheduleDays = {
              monday: true,
              tuesday: true,
              wednesday: true,
              thursday: true,
              friday: true,
              saturday: true,
              sunday: true,
            };

            numbers.current.forEach((element) => {
              e.sms_from_numbers.forEach((elementTwo) => {
                if (element.number === elementTwo.number) {
                  defaultNumbers.push(element);
                }
              });
            });

            result.result.contacts.forEach((element) => {
              if (element.list_name) {
                element.label = element.list_name;
                element.value = element.pk;
                defaultContactList.push(element);
              } else {
                element.label = `${element.firstname} ${element.lastname || ""}`;
                element.value = element.pk;
                defaultContacts.push(element);
              }
            });

            const timeZone = timeZones.find((element) => element.value === e.default_time_zone)

            let defaultScheduleStartTime = ""
            let defaultScheduleEndTime = ""

            if (e.schedule_start_time.split(":")[0] > 0 && e.schedule_start_time.split(":")[0] < 10) {
              const hour = e.schedule_start_time.split(":")[0]
              const minutes = e.schedule_start_time.split(":")[1]

              defaultScheduleStartTime = `0${hour}:${minutes}`
            }

            if (e.schedule_start_time.split(":")[0] > 0 && e.schedule_start_time.split(":")[0] > 9) {
              const hour = e.schedule_start_time.split(":")[0]
              const minutes = e.schedule_start_time.split(":")[1]

              defaultScheduleStartTime = `${hour}:${minutes}`
            }

            if (e.schedule_end_time.split(":")[0] > 0 && e.schedule_end_time.split(":")[0] < 10) {
              const hour = e.schedule_end_time.split(":")[0]
              const minutes = e.schedule_end_time.split(":")[1]
            
              defaultScheduleEndTime = `0${hour}:${minutes}`
            }

            if (e.schedule_end_time.split(":")[0] > 0 && e.schedule_end_time.split(":")[0] > 9) {
              const hour = e.schedule_end_time.split(":")[0]
              const minutes = e.schedule_end_time.split(":")[1]

              defaultScheduleEndTime = `${hour}:${minutes}`
            }

            if (result.result.mms_file && result.result.mms_mime) {
              setImgMessages([
                {
                  data_url: `${result.result?.mms_mime}${result.result?.mms_file}`,
                },
              ]);
            }

            setFormAddCampaign({
              name: e.name,
              campaign_type: e.campaign_type,
              numbers: defaultNumbers,
              campaign_strategy: e.campaign_strategy,
              default_time_zone: timeZone,
              message_text: e.message_text,
              mms_file: result.result?.mms_file,
              mms_mime: result.result?.mms_mime,
              media_filename: result.result?.media_filename,
              contact_list: defaultContactList,
              contacts: defaultContacts,
              schedule_days:
                e.schedule_days && Object.keys(e.schedule_days).length
                  ? e.schedule_days
                  : defaultScheduleDays,
              schedule_start_time: defaultScheduleStartTime,
              schedule_end_time: defaultScheduleEndTime,
              scheduled_date: e.scheduled_date.slice(0, 10),
              determine_time_zone: e.determine_time_zone,
            });
            forceUpdate()
            setLoadingEdit(false);
          } 
        })
        .catch((error) => console.log(error));
  };

  const activeShowPauseCampaign = (e) => {
    if (e.paused) {
      setInfo(e);
      setActivePauseCampaign(true);
      setModalDelete(true);
    }
  };

  const activeShowPlayCampaign = (e) => {
    if (e.play) {
      setInfo(e);
      setActivePlayCampaign(true);
      setModalDelete(true);
    }
  };

  const activeShowStartCampaign = (e) => {
    if (e.start) {
      setInfo(e);
      setActiveStartCampaign(true);
      setModalDelete(true);
    }
  };

  const addCampaign = async (noLoading, start) => {
    try {
      if (!noLoading) setLoadingAddSmartCampaign(true);
  
      let sendContacts = [];
  
      if (formAddCampaign.contacts.length > 0) {
        formAddCampaign.contacts.forEach((element) => {
          sendContacts.push({ contact_id: element.pk });
        });
      }
  
      if (formAddCampaign.contact_list.length > 0) {
        formAddCampaign.contact_list.forEach((element) => {
          sendContacts.push({ contact_list_id: element.pk });
        });
      }
  
      let data = {
        ...formAddCampaign,
        numbers: formAddCampaign.numbers.map((element) => element.value),
        contacts: sendContacts,
        default_time_zone: formAddCampaign.default_time_zone.value,
        mms_file: formAddCampaign?.mms_file,
        mms_mime: formAddCampaign?.mms_mime,
        media_filename: formAddCampaign?.media_filename,
      };
    
      delete data.contact_list;
  
      if (!data.schedule_start_time || !data.schedule_end_time) {
        delete data.schedule_start_time;
        delete data.schedule_end_time;
      }
  
      const { data: result } =  await petitionPost("smartCampaign", { data })
      const smart_campaign_id = result.result.pk

      const followUps = followUpsTable.content.map((element) => ({ data: {
        interval: element.interval,
        interval_type: element.interval_type,
        smart_campaign_id,
        message: element.message,
        use_ai: element.use_ai,
      }}))

      for (const element of followUps) {
        try {
          await petitionPost("followUpSmartCampaigns",{ data: element.data })
        } catch (error) {
          console.log(error)
          const errorMessage = `The smart campaign has been created, but there has been an error with the follow up which has this message: '${element.data.message}', and the following were not created`
          updatedAlertStates("error", "Error", errorMessage, 5000)
          setLoadingAddSmartCampaign(false);
          reloadData()
          return;
        }
      }

      if (start) {
        const startingData = { status: "starting" };
        await petitionPatch("smartCampaign", { campaign_id: smart_campaign_id, data: startingData })
      }

      setLoadingAddSmartCampaign(false);
      reloadData()
    } catch (error) {
      console.log(error)
    }
  };

  const editCampaign = async (noLoading) => {
    try {
      if (!noLoading) setLoadingAddSmartCampaign(true);
    
      let sendContacts = [];
  
      if (formAddCampaign.contacts.length > 0) {
        formAddCampaign.contacts.forEach((element) => {
          sendContacts.push({ contact_id: element.pk });
        });
      }
  
      if (formAddCampaign.contact_list.length > 0) {
        formAddCampaign.contact_list.forEach((element) => {
          sendContacts.push({ contact_list_id: element.pk });
        });
      }
  
      let data = {
        ...formAddCampaign,
        numbers: formAddCampaign.numbers.map((element) => element.value),
        contacts: sendContacts,
        default_time_zone: formAddCampaign.default_time_zone.value,
        mms_file: formAddCampaign?.mms_file,
        mms_mime: formAddCampaign?.mms_mime,
        media_filename: formAddCampaign?.media_filename,
      };
  
      delete data.contact_list;
  
      if (!data.schedule_start_time || !data.schedule_end_time) {
        delete data.schedule_start_time;
        delete data.schedule_end_time;
      }
  
      await petitionPatch("smartCampaign", { campaign_id: info.pk, data })
      const followUpsListOfTable = followUpsTable.content
  
      const newElements = [];
      const changedElements = [];
      const deletedElements = [];
  
      // Iterar sobre el segundo array para verificar elementos nuevos y cambiados
      followUpsListOfTable.forEach((element2) => {
        const element1 = followUpsList.find((el) => el.pk === element2.pk);
  
        if (!element1) {
          // No se encontró en el primer array, por lo tanto es un elemento nuevo
          newElements.push(element2);
        } else {
          // Coinciden los pk, ahora verificar si alguno de los otros campos cambió
          const changeInterval = element1.interval !== element2.interval;
          const changeIntervalType = element1.interval_type !== element2.interval_type;
          const changeMessage = element1.message !== element2.message;
  
          if (changeInterval || changeIntervalType || changeMessage) {
            changedElements.push(element2);
          }
        }
      });
  
      // Verificar elementos eliminados (los que están en array1 pero no en array2)
      followUpsList.forEach((element1) => {
        const element2 = followUpsListOfTable.find((el) => el.pk === element1.pk);
        if (!element2) {
          deletedElements.push(element1);
        }
      });
  
      for (const element of newElements) {
        try {
          delete element.pk
          element.interval = parseInt(element.interval)
          element.smart_campaign_id = info.pk
          await petitionPost("followUpSmartCampaigns",{ data: element })
        } catch (error) {
          console.log(error)
          const errorMessage = `The smart campaign has been updated, but there has been an error with the follow up which has this message: '${element.message}', and the following were not created, updated or deleted`
          updatedAlertStates("error", "Error", errorMessage, 5000)
          setLoadingAddSmartCampaign(false);
          reloadData()
          return;
        }
      }
  
      for (const element of changedElements) {
        try {
          element.interval = parseInt(element.interval)
          await petitionPatch("followUpSmartCampaigns",{ data: element, followUpId: element.pk })
        } catch (error) {
          console.log(error)
          const errorMessage = `The smart campaign has been updated, but there has been an error with the follow up which has this message: '${element.message}', and the following were not created, updated or deleted`
          updatedAlertStates("error", "Error", errorMessage, 5000)
          setLoadingAddSmartCampaign(false);
          reloadData()
          return;
        }
      }
  
      for (const element of deletedElements) {
        try {
          element.interval = parseInt(element.interval)
          await petitionDelete("followUpSmartCampaigns",{ followUpId: element.pk })
        } catch (error) {
          console.log(error)
          const errorMessage = `The smart campaign has been updated, but there has been an error with the follow up which has this message: '${element.message}', and the following were not created, updated or deleted`
          updatedAlertStates("error", "Error", errorMessage, 5000)
          setLoadingAddSmartCampaign(false);
          reloadData()
          return;
        }
      }
  
      setLoadingAddSmartCampaign(false);
      reloadData();
    } catch (error) {
      console.log(error)
    }
  };

  const deleteCampaign = () => {
    setLoadingModal(true);
    petitionDelete("smartCampaign", { campaign_id: info.pk })
      .then(({ data: result }) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const duplicateCampaignFunction = () => {
    setLoadingModal(true);
    petitionGet("duplicateSmartCampaign", { campaign_id: info.pk })
      .then(({ data: result }) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  }

  const pauseCampaign = () => {
    setLoadingModal(true);
    const data = { status: "paused" };
    petitionPatch("smartCampaign", { campaign_id: info.pk, data })
      .then(({ data: result }) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const playCampaign = () => {
    setLoadingModal(true);
    const data = { status: "in_progress" };
    petitionPatch("smartCampaign", { campaign_id: info.pk, data })
      .then(({ data: result }) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const startCampaign = () => {
    setLoadingModal(true);
    const data = { status: "starting" };
    petitionPatch("smartCampaign", { campaign_id: info.pk, data })
      .then(({ data: result }) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const applyFilter = (filterValue) => {
    setFilter(filterValue)
    let parameter = ""

    let formatedFilter = {
      start_date: "",
      end_date: "",
    }

    // Fecha elegida por el usuario (puedes cambiarla según tus necesidades)
    const choosenStartDate = moment(filterValue.start_date);
    const choosenEndDate = moment(filterValue.end_date);

    // Zona horaria elegida por el usuario (puedes cambiarla según tus necesidades)
    const timeZone = myExtension.current?.time_zone; // Ejemplo: GT-5

    // Verifica si la fecha está en UTC
    const isUTCStartDate = choosenStartDate.utcOffset() === 0;
    const isUTCEndDate = choosenEndDate.utcOffset() === 0;

    
    if (!isUTCStartDate) {
      // Convierte la fecha a UTC según la zona horaria seleccionada
      const dateUTCStartDate = choosenStartDate.clone().tz('UTC').tz(timeZone);
      formatedFilter = {
        ...formatedFilter,
        start_date: dateUTCStartDate.format().slice(0, 10)
      }
    } else {
      formatedFilter = {
        ...formatedFilter,
        start_date: filterValue.start_date
      }
    }

    if (!isUTCEndDate) {
      // Convierte la fecha a UTC según la zona horaria seleccionada
      const dateUTCEndDate = choosenEndDate.clone().tz('UTC').tz(timeZone);
      formatedFilter = {
        ...formatedFilter,
        end_date: dateUTCEndDate.format().slice(0, 10)
      }
    } else {
      formatedFilter = {
        ...formatedFilter,
        end_date: filterValue.end_date
      }
    }


    if (filterStatus.status === "all") {
      parameter = getQueryString(filterValue)
    } else {
      parameter = getQueryString(formatedFilter) + getQueryString(filterStatus).replaceAll("?", "&")
    }


    petition(parameter);
  };

  const refreshTable = () => {
    if (loading || loadingRefresh) return;

    setLoadingRefresh(true)

    let parameter = ""

    if (filter.start_date && filter.end_date) {
      parameter = getQueryString(filter)
    }

    if (filterStatus.status !== "all") {
      parameter += getQueryString(filterStatus).replaceAll("?", "&")
    }

    if (parameter) {
      parameter = parameter.includes("?") ? parameter : `?${parameter.slice(1)}`
    }

    petition(parameter, true)
  }

  const updatedAlertStates = (type, title, message, time) => {
    setAlertType(type)
    setAlertTitle(title)
    setAlertMessage(message)
    setShowErrorTime(time)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, 3300)
  }

  const handleSendMessage = async (phone) => {
    if (loadingSend) return;

    if (!validUSPhoneNumber(phone)) {
      const message = "Please enter a valid phone number"
      updatedAlertStates("error", "Error", message, 3000)
      return;
    }

    try {
      setLoadingSend(true)

      const body = formAddCampaign.message_text
      const number = formAddCampaign.numbers[0].number
      const toNumber = "+" + phone

      const formData = createFormData(body, number, toNumber);
      await petitionPost('sendSms', { data: formData, number });
      setLoadingSend(false)

      const message = "Message sent successfully"
      updatedAlertStates("success", "Success", message, 3000)
    } catch (error) {
      console.log(error)
      setLoadingSend(false)

      const message = "An error has ocurred"
      updatedAlertStates("error", "Error", message, 3000)
    }
  }

  const petition = async (parameter, noLoading) => {
    if (!noLoading) setLoading(true);
    
    await petitionGet("smartCampaigns", { parameter })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.icon = selectIcon[element.campaign_type];
          element.edited = element.status === "pending" ? true : false;
          element.paused = element.status === "in_progress" ? true : false;
          element.play = element.status === "paused" ? true : false;
          element.start = element.status === "pending" ? true : false;
          element.formatedType = formatText(element.campaign_type);
          element.formatedStatus = formatText(element.status);
          element.formatedDelivered = [
            {
              name: element.delivered,
              icon: (
                <img
                  src={bigEye}
                  alt=""
                  style={{ width: "16px", height: "16px" }}
                />
              ),
            },
          ];
          element.formatedRejected = [
            {
              name: element.rejected,
              icon: (
                <img
                  src={closeCircle}
                  alt=""
                  style={{ width: "16px", height: "16px" }}
                />
              ),
            },
          ];
          element.formatedPending = [
            {
              name: element.messages_pending,
              icon: (
                <img
                  src={wait}
                  alt=""
                  style={{ width: "16px", height: "16px" }}
                />
              ),
            },
          ];
          element.formatedResponded = [
            {
              name: element?.responded || 0,
              icon: (
                <img
                  src={contacts}
                  alt=""
                  style={{ width: "16px", height: "16px" }}
                />
              ),
            },
          ]
        });

        setDataTable({
          columns: [
            { name: "Name", key: "name" },
            { name: "Type", key: "formatedType" },
            { name: "Start Date", key: "scheduled_date" },
            { name: "Messages Delivered", key: "formatedDelivered" },
            { name: "Messages Failed", key: "formatedRejected" },
            { name: "Messages Pending", key: "formatedPending" },
            { name: "Responded", key: "formatedResponded" },
            { name: "Status", key: "formatedStatus" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit Details",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeEditCampaign,
              },
              {
                name: "Pause",
                icon: <FontAwesomeIcon icon={faPause} />,
                handleClick: activeShowPauseCampaign,
                canBeDisabled: true,
                keyDisabled: "paused",
              },
              {
                name: "Play",
                icon: <FontAwesomeIcon icon={faPlay} />,
                handleClick: activeShowPlayCampaign,
                canBeDisabled: true,
                keyDisabled: "play",
              },
              {
                name: "Start Campaign",
                icon: <FontAwesomeIcon icon={faFlagCheckered} />,
                handleClick: activeShowStartCampaign,
                canBeDisabled: true,
                keyDisabled: "start",
              },
              {
                name: "View Logs",
                icon: (
                  <img
                    style={{ width: "20px", height: "20px", marginLeft: "-4px" }}
                    src={view}
                    alt=""
                  />
                ),
                handleClick: activeViewLogs,
              },
              {
                name: "View Followups",
                icon: (
                  <img
                    style={{ width: "20px", height: "20px", marginLeft: "-4px" }}
                    src={view}
                    alt=""
                  />
                ),
                handleClick: activeViewFollowTasks,
              },
              {
                name: "Duplicate Campaign",
                icon: <FontAwesomeIcon icon={faCopy} />,
                handleClick: activeDuplicateCampaign,
              },
            ],
          },
        });
        setLoading(false);
        setLoadingRefresh(false);
      })
      .catch((error) => console.log(error));
  };

  const petitions = async (parameter) => {
    await petitionGet("assignedNumbers")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.number;
          element.value = element.number;
        });

        numbers.current = result.result;
      })
      .catch((error) => console.log(error));

    await petition(parameter || "");
  };

  useEffect(() => {
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition()

        if (data && Object.keys(data).length > 0) {
          myExtension.current = data

          const timeZone = timeZones.find((element) => element.value === data.time_zone)

          setIsArrowVisible(false);
          resetFunction();
          setDefaultTimeZone(timeZone)
          setFormAddCampaign({ ...formAddCampaign, default_time_zone: timeZone })
          petitions();
        } 

      } catch(error) {
        console.log(error)
      }
    }

    myExtensionData()
  }, []);

  return (
    <div className="itp-test">
      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      {modalDelete && (
        <ModalDelete
           modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingModal}
          modalFunction={selectModalFunction}
          title={selectTitle()}
          text={selectText()}
        />
      )}
          
      {modalInfo && (
        <ModalInfomation
          modal={modalInfo}
          closeModal={closeMenu}
          loading={loadingModal}
          title={selectTitle()}
          text={selectText()}
        />
      )}

      <CustomTableMain 
        data={dataTable}
        loading={loading || loadingRefresh}
        module="smart campaigns"
        showSelectSingleCheckbox={true}
        paginationData={parseInt(itemsPerPage.current)}
        onChangeItemsPerPage={handleChangeItemsPerPage}
        actionsLeft={[
          {
            component: (
              <SearchByDate 
                SearchByDateText="Search By Start Date:"
                applyFilter={applyFilter}
                defaultValue={filter}
                loading={loading || loadingRefresh}
              />
            )
          },
          {
            component: (
              <select
                name="status"
                onChange={handleChangeStatus}
                value={filterStatus.status}
                className="form-select-itp"
              >
                <option value="all">All</option>
                <option value="completed">Completed</option>
                <option value="in_progress">In Progress</option>
                <option value="pending">Pending</option>
              </select>
            )
          }
        ]}
        actionsRight={[
          {
            component: <RefreshIcon className="cursor-pointer" onClick={refreshTable} />
          },
          {
            component: (
              <AddButton 
                name="Add New Campaign"
                handleClick={activeAddCampaign}
              />
            )
          }
        ]}
        columnConfig={{
          "Type": {
            type: "iconAndText",
            whichIsFirst: "icon"
          },
          "Start Date": {
            type: "date"
          },
          "Messages Delivered": {
            type: "arrayInfo",
          },
          "Messages Failed": {
            type: "arrayInfo",
          },
          "Messages Pending": {
            type: "arrayInfo",
          },
          "Responded": {
            type: "arrayInfo",
          },
        }}
      />

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title={`${info ? "Edit" : "Add"} New Campaign`}
        subtitle={`${info ? "Edit" : "Add"} the details of new campaign`}
        to="to Smart Campaignsss"
      >
        {openMenu && (
          <>
            <AddCampaign
              form={formAddCampaign}
              setForm={setFormAddCampaign}
              addCampaign={addCampaign}
              loadingAddSmartCampaign={loadingAddSmartCampaign}
              step={stepAddCampaign}
              setStep={setStepAddCampaign}
              loading={loadingEdit}
              setLoading={setLoadingEdit}
              numbers={numbers.current}
              edit={info}
              editCampaign={editCampaign}
              imgMessages={imgMessages}
              setImgMessages={setImgMessages}
              optionsMessageBody={optionsMessageBody}
              setOptionsMessageBody={setOptionsMessageBody}
              handleSendMessage={handleSendMessage}
              loadingSend={loadingSend}
              updatedAlertStates={updatedAlertStates}
              followUpsTable={followUpsTable}
              setFollowUpsTable={setFollowUpsTable}
            />
          </>
        )}
      </MenuRight>
    </div>
  );
};

export default SmartCampaigns;
